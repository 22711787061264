
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        
































































$hero-visual-width: 1472;
$hero-visual-height: 360;

.hero {
  // Prevent x overflow if page has extra long title with extra long words...
  overflow-wrap: break-word;
  display: flex;
  align-items: center;

  // Parent should automatically apply 'page__section' class to component
  // so we override unwanted padding-top and remove pseudo-elemnt border
  margin-top: 0;

  &::before {
    content: none;
  }

  ::v-deep {
    .flexible-video {
      padding-right: 0;
      padding-left: 0;
    }

    .flexible-video__picture {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      min-height: unset;
    }

    .flexible-video__button {
      z-index: 1;
      bottom: 7.25%;
      left: 2.86%;
      transform: unset;
    }
  }

  @include mq($until: s) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.hero__content {
  flex-shrink: 0;
  flex-basis: 50%;
}

.hero__headline {
  position: relative;
  margin-bottom: 1rem;
  color: rgba($c-black, 0.4);
  font-size: 1.5rem;
  line-height: 2.8rem;
}

.hero__title {
  @extend %fw-light;
  // prettier-ignore
  @include fluid(font-size, (xxs: 30px, xxl: 60px));

  // prettier-ignore
  @include fluid(margin-top, (s: 20px, m: 0px));

  line-height: 1.1;
}

.hero__subtitle {
  margin-top: 2rem;
  color: $c-gray-dark;
}

.hero__picture__logo-container {
  position: relative;
}

.hero__picture__logo.picture {
  // prettier-ignore
  @include fluid(padding-top, (xxs: 10px, xxl: 30px));
  // prettier-ignore
  @include fluid(padding-bottom, (xxs: 10px, xxl: 30px));
  // prettier-ignore
  @include fluid(padding-right, (xxs: 20px, xxl: 50px));

  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: auto;
  height: auto;
  min-height: unset;
  background-color: $c-white;

  ::v-deep {
    .picture-inner {
      position: relative;
      top: unset;
      right: unset;
      bottom: unset;
      left: unset;
      width: auto;
      height: auto;

      @include fluid(
        max-width,
        (
          xxs: 100px,
          xxl: 225px,
        )
      );
    }
  }
}

.hero__picture-outer {
  // prettier-ignore
  @include fluid(margin-top, (s: 20px, m: -40px));

  position: relative;
  width: 100%;

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    content: '';
    display: block;
    width: 100vh;
    height: 100%;
    background: linear-gradient(180deg, #3ca300 0%, #66c430 100%);
    transform: translateX(100%);
  }
}

.hero__picture-inner {
  // prettier-ignore
  @include fluid(height, (xxs: 300px, xxl: 400px));

  position: relative;
  overflow: hidden;
  width: 100%;

  @include mq($until: s) {
    padding-right: 0;
    padding-left: 0;
  }
}

.hero__picture {
  @include image-fit;

  &.picture {
    @include aspect-ratio($hero-visual-width, $hero-visual-height);

    min-height: unset;

    .process & {
      $hero-visual-width: 2940;
      $hero-visual-height: 1600;

      @include aspect-ratio($hero-visual-width, $hero-visual-height);
    }
  }
}

.hero__video-container {
  @include get-all-space;
  // @include aspect-ratio($hero-visual-width, $hero-visual-height);

  // .process & {
  //   $hero-visual-width: 2940;
  //   $hero-visual-height: 1600;

  //   @include aspect-ratio($hero-visual-width, $hero-visual-height);
  // }
}

.hero__video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
