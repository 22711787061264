//Avoid typekit FOUT
html {
  --scrollbar-width: 15px;
  --scroll-padding: #{$header-height-mobile};

  overflow-x: hidden;

  scroll-padding-top: var(--scroll-padding);

  @include mq(l) {
    --scroll-padding: #{$header-height};
  }

  &.has-internal-sticky-nav {
    // Add 2px to avoid "double border"
    --scroll-padding: #{$header-height-mobile + $internal-nav-height-mobile +
      2px};

    @include mq(l) {
      // Add 2px to avoid "double border"
      --scroll-padding: #{$header-height + $internal-nav-height + 2px};
    }
  }

  &.js {
    opacity: 0;
    transition: opacity 0.2s ease-in-out;

    &.wf-active,
    &.wf-inactive,
    &.ready {
      opacity: 1;
    }
  }
}

// SVG sprite
body > svg {
  display: none;
}

// RWD
img {
  max-width: 100%;
  height: auto;
}

// NO SCROLL
.no-scroll {
  overflow-y: hidden;
  height: 100vh;
  padding-right: var(--scrollbar-width, 15px);
}

// VARIOUS
hr {
  height: 1px;
  background-color: $c-gray-darker;
  border: 0;
}

.is-hidden {
  visibility: hidden;
}

.page {
  &:not(.is-home) {
    padding-top: $header-height-mobile;

    @include mq($header-breakpoint) {
      padding-top: $header-height;
    }
  }
}

.process {
  &:not(.is-home) {
    padding-top: $header-height-mobile;

    @include mq($header-breakpoint) {
      padding-top: $header-height;
    }
  }
}

.product {
  &:not(.is-home) {
    padding-top: $header-height-mobile;

    @include mq($header-breakpoint) {
      padding-top: $header-height;
    }
  }
}

.page__section,
[class*='page__section--'] {
  @include default-margin;

  position: relative;

  &:not([class*='--no-border']) {
    // &::before {
    //   content: '';
    //   position: absolute;
    //   top: 0;
    //   right: col(1, 16);
    //   left: col(1, 16);
    //   height: 1px;
    //   background-color: rgba($c-black, 0.15);
    // }
  }

  &[class*='--no-pt'] {
    padding-top: 0;
  }

  &[class*='--no-pb'] {
    padding-bottom: 0;
  }

  // Avoid "double padding" on siblings sections.
  // We want to keep next section padding if current section does not have padding bottom though.
  // &:not([class*='--no-pb'], .flexible-advantages, .flexible-keyfacts.has-image) {
  //   + .page__section {
  //     padding-top: 0;
  //   }
  // }
}

.grecaptcha-badge {
  visibility: hidden;
}
